import * as React from "react";
import Box from "@mui/material/Box";

import CardContent from "@mui/material/CardContent";

import Typography from "@mui/material/Typography";

import "./FwCard.css";

import CusButton from "../../../../SharedComps/CusButton/CusButton";
import LaunchIcon from "@mui/icons-material/Launch";
import { Link } from "@mui/material";

export default function FwCard({ fw }) {
  const { framework, description, demo } = fw;
  const card = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5" component="div" sx={{ color: "info.light" }}>
          {framework}
        </Typography>
      </CardContent>
    </React.Fragment>
  );
  return (
    <Box
      component={"div"}
      className="card"
      variant="outlined"
      bgcolor={"transparent"}
      data-aos="flip-right"
      data-aos-duration="500"
      data-aos-delay="50"
      data-aos-easing="ease-in-out"
    >
      <Box className="card-side card-side-front">{card}</Box>
      <Box className="card-side card-side-back">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            width: "100%",
            height: "100%",

            p: 2,
            fontSize: { xl: "1.2rem" },
            flexDirection: "column",
          }}
        >
          <Typography
            variant="p"
            component={"p"}
            color="primary"
            sx={{ flexGrow: 1 }}
          >
            {description}
          </Typography>
          <Link href={demo} target="_blank" sx={{ textDecoration: "none" }}>
            <CusButton text="Demonstration" symbol={<LaunchIcon />} />
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
