import { Box, Button } from "@mui/material";
import React from "react";

import "./CusButton.css";
const CusButton = ({ text, symbol, applyBubble, width }) => {
  const content = !applyBubble && "none";
  const btnWitdth = width && "100%";
  return (
    <div style={{ width: width }}>
      <Box
        component={"button"}
        className="my-btn"
        data-after-content={text}
        sx={{
          "&::after": { content: "attr(data-after-content)" },
          "&::before": { content },
          display: "flex",
          alignItems: "center",
          columnGap: 1,
          width: btnWitdth,
        }}
        href="https://twitter.com/kamildyrek"
      >
        <div class="left"></div>
        {text} {symbol && symbol}
        <Box
          className="right"
          data-after-content={text}
          sx={{ "&::after": { content: "attr(data-after-content)" } }}
        ></Box>
      </Box>
    </div>
  );
};

export default CusButton;
