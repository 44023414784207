import { Copyright } from "@mui/icons-material"
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    InputLabel,
    Paper,
    TextareaAutosize,
    TextField,
    Typography,
} from "@mui/material"
import React, { useContext, useEffect, useRef } from "react"
import emailjs from "@emailjs/browser"
import { MyLoader } from "../../contexts/LoaderContext"
import Touch from "./Touch/Touch"

const Contact = () => {
    const { setLoading } = useContext(MyLoader)
    useEffect(() => {
        setLoading(false)
        return () => setLoading(true)
    }, [setLoading])
    const form = useRef()
    const handleSubmit = (e) => {
        e.preventDefault()

        emailjs
            .sendForm(
                "service_xcretnc",
                "template_s08zu5v",
                form.current,
                "RxNDI5DL2EnEO8doy"
            )
            .then(
                (result) => {
                    console.log(result)
                    console.log(result.text)
                    console.log(e.target)
                    e.target.reset()
                },
                (error) => {
                    console.log(error.text)
                }
            )
    }
    return (
        <Box sx={{ mx: 0, width: "100%", minHeight: "100vh" }}>
            <Touch />
            <Grid container component="main" sx={{ width: "100%", mt: 12 }}>
                <Grid
                    item
                    xs={12}
                    component={Paper}
                    sx={{ bgcolor: "primary.main", width: "100%" }}
                    square
                >
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar> */}
                        <Typography
                            component="h1"
                            variant="h5"
                            sx={{ color: "secondary.main", fontSize: "1.8rem" }}
                        >
                            Leave a messeage
                        </Typography>
                        <Box
                            ref={form}
                            component="form"
                            noValidate
                            onSubmit={handleSubmit}
                            sx={{
                                mt: 1,
                                minWidth: { lg: "80rem" },
                                width: { lg: "80rem" },
                                minHeight: "100vh",
                            }}
                        >
                            <InputLabel
                                sx={{ color: "info.main" }}
                                htmlFor="user_name"
                            >
                                Your Name
                            </InputLabel>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="user_name"
                                autoFocus
                                sx={{
                                    backgroundColor: "white",
                                    borderRadius: "5px",
                                    maxWidth: "100%",
                                    mb: 3,
                                }}
                            />
                            <InputLabel
                                sx={{ color: "info.main" }}
                                htmlFor="user_email"
                            >
                                Your Email
                            </InputLabel>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="user_email"
                                autoComplete="email"
                                type={"email"}
                                autoFocus
                                sx={{
                                    backgroundColor: "white",
                                    borderRadius: "5px",
                                    maxWidth: "100%",
                                    mb: 3,
                                }}
                            />
                            <InputLabel
                                sx={{ color: "info.main", mb: 2 }}
                                htmlFor="message"
                            >
                                Message
                            </InputLabel>
                            <TextareaAutosize
                                aria-label="empty textarea"
                                placeholder="Empty"
                                required
                                name="message"
                                style={{ width: "100%", height: "15rem" }}
                                autoFocus
                                minRows={12}
                            />

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="info"
                                sx={{ mt: 3, mb: 2, py: 2 }}
                            >
                                Send
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Contact
