import { Box, keyframes, Link, styled, Typography } from "@mui/material"

import bootstrap from "../../../Static/Images/bootstrap.svg"
import daisyui from "../../../Static/Images/daisyUI.svg"
import firebase from "../../../Static/Images/firebase.svg"
import nextjs from "../../../Static/Images/nextjs.png"
import mongodb from "../../../Static/Images/mongodb.svg"
import netlify from "../../../Static/Images/netlify.svg"
import npm from "../../../Static/Images/npm.svg"
import tailwind from "../../../Static/Images/tailwind.svg"
import tanstack from "../../../Static/Images/tanstack.png"
import postgresql from "../../../Static/Images/postgresql.svg"
import mysql from "../../../Static/Images/mysql.png"
import nextauth from "../../../Static/Images/nextauth.png"
import prisma from "../../../Static/Images/prisma.png"
import vercel from "../../../Static/Images/vercel.svg"

import React from "react"
import CusButton from "../../../SharedComps/CusButton/CusButton"
import LaunchIcon from "@mui/icons-material/Launch"
import pong from "../../../Static/Files/pong_game.exe"
import snake from "../../../Static/Files/snake_game.exe"
import DownloadIcon from "@mui/icons-material/Download"

const spin = keyframes`
0%{
  transform : rotate(0deg)
}
100% {
transform: rotate(360deg) ;
}
`
const TechContainer = styled(Box)(({ theme }) => ({
    position: "relative",
    border: "3px solid #001c55",
    borderRadius: "50%",
    maxWidth: "100vw",

    margin: "0 auto",
    overflow: "hidden",
    background: "radial-gradient(#00072d , #001c55  , #00072d )",
}))
const TechCircle = styled(Box)(({ theme }) => ({
    position: "absolute",
    left: "45.3%",

    borderRadius: "50%",
}))

const dob = [
    bootstrap,
    daisyui,
    firebase,
    postgresql,
    mongodb,
    netlify,
    vercel,
    tailwind,
    tanstack,
    nextjs,
    mysql,
    nextauth,
    prisma,
    npm,
]

const Technologies = () => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                columnGap: { lg: 3 },
                flexDirection: { xs: "column", xl: "row" },
            }}
        >
            <Box
                sx={{
                    width: { xs: "100%", xl: "50%" },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    rowGap: "3rem",
                    border: "2px solid #001c55",

                    borderTopLeftRadius: "15rem",
                    borderBottomRightRadius: "15rem",
                    py: 16,
                    ml: { xl: 4 },
                    my: 8,
                }}
            >
                <Link
                    sx={{ textDecoration: "none" }}
                    href="https://github.com/FahimFaisalKhan?tab=repositories"
                    target="_blank"
                >
                    <CusButton
                        text={"All Projects"}
                        symbol={<LaunchIcon sx={{ ml: 2.5, mb: -0.55 }} />}
                        width="15rem"
                    />
                </Link>
                <Link
                    sx={{ textDecoration: "none" }}
                    href="https://github.com/FahimFaisalKhan/Web_Scrapping"
                    target="_blank"
                >
                    <CusButton
                        text={"Web Scrapping"}
                        symbol={<LaunchIcon sx={{ ml: 1, mb: -0.55 }} />}
                        width="15rem"
                    />
                </Link>
                <Link
                    sx={{ textDecoration: "none" }}
                    href={pong}
                    download="Pong Game.exe"
                >
                    <CusButton
                        text={"Pong game"}
                        symbol={<DownloadIcon sx={{ ml: 7, mb: -0.55 }} />}
                        width="15rem"
                    />
                </Link>
                <Link
                    sx={{ textDecoration: "none" }}
                    href={snake}
                    download="Snake Game.exe"
                >
                    <CusButton
                        text={"Snake game"}
                        symbol={<DownloadIcon sx={{ ml: 6, mb: -0.55 }} />}
                        width="15rem"
                    />
                </Link>
            </Box>
            <Box
                sx={{ position: "relative", width: { xs: "100%", xl: "50%" } }}
            >
                <Typography
                    component="h1"
                    variant="p"
                    gutterBottom={true}
                    align="center"
                    sx={{
                        fontWeight: 200,
                        fontSize: { xs: ".7rem", lg: "1.3rem" },
                        position: "absolute",
                        top: { xs: "50%", xl: "50%" },
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: { xs: "7rem", sm: "10rem", lg: "15rem" },
                        height: { xs: "7rem", sm: "10rem", lg: "15rem" },
                        textAlign: "left",
                        borderRadius: "50%",
                        zIndex: 50,
                        color: "grey.100",
                        border: ".5rem solid #00072d",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Box component={"p"} sx={{ ml: { xs: 1.5, lg: 3.5 } }}>
                        Technologies I feel comfortable to work with.
                    </Box>
                </Typography>
                <TechContainer
                    sx={{
                        width: { xs: "50vh", sm: "80vh" },
                        height: { xs: "50vh", sm: "80vh" },
                        animation: { md: `${spin} 90s linear infinite` },
                    }}
                >
                    {dob.map((d, i) => {
                        return (
                            <TechCircle
                                sx={{
                                    top: {
                                        xs: "43.5%",
                                        md: "47%",
                                        lg: "47%",
                                        xl: "45%",
                                    },
                                    transform: {
                                        xs: `rotate(${
                                            i * 25.5 + 25.5
                                        }deg) translateX(120px)`,
                                        sm: `rotate(${
                                            i * 25.5 + 25.5
                                        }deg) translateX(200px)`,
                                        lg: `rotate(${
                                            i * 25.5 + 25.5
                                        }deg) translateX(260px)`,
                                        xl: `rotate(${
                                            i * 25.5 + 25.5
                                        }deg) translateX(300px)`,
                                    },
                                    // animation: `${spin} 5s infinite linear`,
                                }}
                            >
                                <Box
                                    component={"img"}
                                    src={d}
                                    sx={{
                                        width: {
                                            xs: "2rem",
                                            sm: "2.6rem",
                                            lg: "3rem",
                                            xl: "4rem",
                                        },
                                        // height: {
                                        //     xs: "2rem",
                                        //     sm: "2.6rem",
                                        //     lg: "3rem",
                                        //     xl: "auto",
                                        // },
                                        borderRadius: "20%",
                                        transform: `rotate(-${
                                            i * 25.5 + 25.5
                                        }deg)`,
                                        animation: {
                                            md: `${keyframes`

                              to {
                        transform: rotate(-${383 + i * 25.5}deg ) ;
                      }
                    `} 90s linear infinite `,
                                        },
                                    }}
                                />
                            </TechCircle>
                        )
                    })}
                </TechContainer>
            </Box>
        </Box>
    )
}

export default Technologies
